import React, { useContext, useState } from "react";
import AdultsDropdown from "../components/AdultsDropdown";
import CheckIn from "../components/CheckIn";
import { RoomContext } from "../context/RoomContext";

const BookForm = () => {
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [guestCount, setGuestCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Hata mesajı
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal durumu
  const { handleClick } = useContext(RoomContext);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Hata mesajları kontrolü
    if (!dates.startDate || !dates.endDate) {
      setErrorMessage("Lütfen tarih aralığını seçin.");
      setIsModalOpen(true);
      return;
    }

    if (
      new Date(dates.startDate).getTime() === new Date(dates.endDate).getTime()
    ) {
      setErrorMessage("Başlangıç ve bitiş tarihleri aynı olamaz.");
      setIsModalOpen(true);
      return;
    }

    if (guestCount === 0) {
      setErrorMessage("Lütfen misafir sayısını seçin.");
      setIsModalOpen(true);
      return;
    }

    setErrorMessage("");
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      handleClick(e, dates, guestCount);
      document
        .querySelector("#room-container")
        ?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  };

  return (
    <>
      <form
        className="h-auto w-full mx-auto flex flex-col lg:flex-row items-center lg:justify-between p-4 lg:space-x-4 font-tertiary"
        onSubmit={handleFormSubmit}
      >
        <div>
          <h1 className="font-semibold text-2xl font-tertiary mb-4 lg:mb-0">
            Rezervasyon
          </h1>
        </div>

        <div className="w-full lg:w-auto flex-grow">
          <CheckIn setDates={setDates} />
        </div>

        <div className="w-full lg:w-auto flex-grow mt-4 lg:mt-0">
          <AdultsDropdown setGuestCount={setGuestCount} />
        </div>

        <div className="w-full lg:w-auto mt-4 lg:mt-0 flex justify-center">
          <button
            className={`bg-accent hover:bg-accent-hover text-white font-bold py-2 px-8 rounded-full flex items-center justify-center min-w-[100px] h-[48px] ${
              loading ? "opacity-50 pointer-events-none" : ""
            }`}
            type="submit"
            disabled={loading}
            id="submit"
          >
            {loading ? (
              <div className="loader w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin"></div>
            ) : (
              "Ara"
            )}
          </button>
        </div>
      </form>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-80 text-center">
            <h2 className="text-lg font-bold text-red-500 mb-2">Hata</h2>
            <p className="text-gray-700 mb-4">{errorMessage}</p>
            <button
              onClick={() => setIsModalOpen(false)}
              className="bg-accent text-white py-2 px-4 rounded hover:bg-accent-hover transition-colors"
            >
              Tamam
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default BookForm;
