// rooms images
import Room1Img from "./assets/img/rooms/1.webp";
import Room1ImgLg from "./assets/img/rooms/1-lg.webp";
import Room2Img from "./assets/img/rooms/2.webp";
import Room2ImgLg from "./assets/img/rooms/2-lg.webp";
import Room3Img from "./assets/img/rooms/3.webp";
import Room3ImgLg from "./assets/img/rooms/3-lg.webp";

import Room1Img1 from "./assets/img/rooms/Room1Img1.webp";
import Room1Img2 from "./assets/img/rooms/Room1Img2.webp";
import Room1Img3 from "./assets/img/rooms/Room1Img3.webp";
import Room1Img4 from "./assets/img/rooms/Room1Img4.webp";

import Room2Img1 from "./assets/img/rooms/Room2Img1.webp";
import Room2Img2 from "./assets/img/rooms/Room2Img2.webp";
import Room2Img3 from "./assets/img/rooms/Room2Img3.webp";
import Room2Img4 from "./assets/img/rooms/Room2Img4.webp";
import Room2Img5 from "./assets/img/rooms/Room2Img5.webp";
import Room2Img6 from "./assets/img/rooms/Room2Img6.webp";
import Room2Img7 from "./assets/img/rooms/Room2Img7.webp";
import Room2Img8 from "./assets/img/rooms/Room2Img8.webp";
import Room2Img9 from "./assets/img/rooms/Room2Img9.webp";

import Room3Img1 from "./assets/img/rooms/Room3Img1.webp";
import Room3Img2 from "./assets/img/rooms/Room3Img2.webp";
import Room3Img3 from "./assets/img/rooms/Room3Img3.webp";
import Room3Img4 from "./assets/img/rooms/Room3Img4.webp";
import Room3Img5 from "./assets/img/rooms/Room3Img5.webp";
import Room3Img6 from "./assets/img/rooms/Room3Img6.webp";
import Room3Img7 from "./assets/img/rooms/Room3Img7.webp";
import Room3Img8 from "./assets/img/rooms/Room3Img8.webp";
import Room3Img9 from "./assets/img/rooms/Room3Img9.webp";
import Room3Img10 from "./assets/img/rooms/Room3Img10.webp";
import Room3Img11 from "./assets/img/rooms/Room3Img10.webp";
// import icons
import {
  FaWifi,
  FaParking,
  FaSwimmingPool,
  FaCocktail,
  FaShower,
} from "react-icons/fa";
import { ImSpoonKnife } from "react-icons/im";
import {
  MdOutlineGrass,
  MdBalcony,
  MdRoomService,
  MdCleaningServices,
} from "react-icons/md";
import { PiTelevisionSimple } from "react-icons/pi";

export const roomData = [
  {
    id: 1,
    name: "Balkonlu Deluxe Oda",
    description:
      "Rezervasyon yapmak için tarih aralığını ve misafir sayısını seçiniz.",
    facilities: [
      { name: "İnternet", icon: <FaWifi /> },
      { name: "Akıllı Televizyon", icon: <PiTelevisionSimple /> },
      { name: "Banyo", icon: <FaShower /> },
      { name: "Balkon", icon: <MdBalcony /> },
      { name: "Oda Temizliği", icon: <MdCleaningServices /> },
    ],
    hotelfacilities: [
      { name: "İnternet", icon: <FaWifi /> },
      { name: "Banyo", icon: <FaShower /> },
      { name: "Park Alanı", icon: <FaParking /> },
      { name: "Yüzme Havuzu", icon: <FaSwimmingPool /> },
      { name: "Kahvaltı", icon: <ImSpoonKnife /> },
      { name: "Bar", icon: <FaCocktail /> },
      { name: "Resepsiyon 7/24", icon: <MdRoomService /> },
    ],
    size: 25,
    maxPerson: 3,
    image: Room3Img,
    imageLg: Room3ImgLg,
    images: [
      Room3Img1,
      Room3Img2,
      Room3Img3,
      Room3Img4,
      Room3Img5,
      Room3Img6,
      Room3Img7,
      Room3Img8,
      Room3Img9,
      Room3Img10,
      Room3Img11,
    ],
  },
  {
    id: 2,
    name: "Teraslı Oda",
    description:
      "Rezervasyon yapmak için tarih aralığını ve misafir sayısını seçiniz.",
    facilities: [
      { name: "İnternet", icon: <FaWifi /> },
      { name: "Akıllı Televizyon", icon: <PiTelevisionSimple /> },
      { name: "Banyo", icon: <FaShower /> },
      { name: "Bahçe", icon: <MdOutlineGrass /> },
      { name: "Oda Temizliği", icon: <MdCleaningServices /> },
    ],
    hotelfacilities: [
      { name: "İnternet", icon: <FaWifi /> },
      { name: "Park Alanı", icon: <FaParking /> },
      { name: "Yüzme Havuzu", icon: <FaSwimmingPool /> },
      { name: "Kahvaltı", icon: <ImSpoonKnife /> },
      { name: "Bar", icon: <FaCocktail /> },
      { name: "Resepsiyon 7/24", icon: <MdRoomService /> },
    ],
    size: 25,
    maxPerson: 3,
    image: Room2Img,
    imageLg: Room2ImgLg,
    images: [
      Room2Img1,
      Room2Img2,
      Room2Img3,
      Room2Img4,
      Room2Img5,
      Room2Img6,
      Room2Img7,
      Room2Img8,
      Room2Img9,
      Room3Img10,
    ],
  },
  {
    id: 3,
    name: "Bodrum Ekonomik",
    description:
      "Rezervasyon yapmak için tarih aralığını ve misafir sayısını seçiniz.",
    facilities: [
      { name: "İnternet", icon: <FaWifi /> },
      { name: "Akıllı Televizyon", icon: <PiTelevisionSimple /> },
      { name: "Banyo", icon: <FaShower /> },
      { name: "Oda Temizliği", icon: <MdCleaningServices /> },
    ],
    hotelfacilities: [
      { name: "İnternet", icon: <FaWifi /> },
      { name: "Banyo", icon: <FaShower /> },
      { name: "Park Alanı", icon: <FaParking /> },
      { name: "Yüzme Havuzu", icon: <FaSwimmingPool /> },
      { name: "Kahvaltı", icon: <ImSpoonKnife /> },
      { name: "Bar", icon: <FaCocktail /> },
      { name: "Resepsiyon 7/24", icon: <MdRoomService /> },
    ],
    size: 25,
    maxPerson: 2,
    image: Room1Img,
    imageLg: Room1ImgLg,
    images: [Room1Img1, Room1Img2, Room1Img3, Room1Img4],
  },
];

export const calculatePrices = (dates, guestCount) => {
  const { startDate, endDate } = dates;
  if (!startDate || !endDate) {
    alert("Lütfen geçerli tarih aralıklarını seçiniz.");
    return null;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);
  const timeDiff = end.getTime() - start.getTime();
  const dayCount = Math.ceil(timeDiff / (1000 * 3600 * 24));

  const getPrice = (month, guestCount) => {
    let basePrice;
    switch (month) {
      case 6: // Temmuz
        basePrice = { bodrum: 4000, bahceli: 5000, balkonlu: 6000 };
        break;
      case 7: // Ağustos
        basePrice = { bodrum: 4500, bahceli: 5500, balkonlu: 6500 };
        break;
      case 8: // Eylül
        basePrice = { bodrum: 3000, bahceli: 4000, balkonlu: 5000 };
        break;
      default:
        return null;
    }

    const extraPersonCharge = guestCount > 2 ? 1500 : 0;

    return {
      bodrum: basePrice.bodrum + extraPersonCharge,
      bahceli: basePrice.bahceli + extraPersonCharge,
      balkonlu: basePrice.balkonlu + extraPersonCharge,
      basePrice: basePrice,
      extraPersonCharge: extraPersonCharge,
    };
  };

  const startMonth = start.getMonth();
  const prices = getPrice(startMonth, guestCount);

  if (!prices) {
    alert("Seçilen tarih aralığı geçerli değil.");
    return null;
  }

  const dailyPrices = {
    bodrum: prices.bodrum,
    bahceli: prices.bahceli,
    balkonlu: prices.balkonlu,
  };

  const totalPrices = {
    bodrum: dailyPrices.bodrum * dayCount,
    bahceli: dailyPrices.bahceli * dayCount,
    balkonlu: dailyPrices.balkonlu * dayCount,
  };

  return { dailyPrices, totalPrices };
};

// Fiyatları odalara işleyen fonksiyon
export const updateRoomPrices = (rooms, dates, guestCount) => {
  const priceInfo = calculatePrices(dates, guestCount);
  if (!priceInfo) return rooms;

  return rooms.map((room) => ({
    ...room,
    price: priceInfo.totalPrices[room.name.toLowerCase()] || room.price,
  }));
};
