import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";

const CheckIn = ({ setDates }) => {
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    setValue(newValue);
    setDates(newValue);
  };

  // Güncel tarihi ayarla
  const today = new Date();

  return (
    <Datepicker
      minDate={today}
      primaryColor={"sky"}
      value={value}
      onChange={handleValueChange}
      displayFormat={"DD/MM/YYYY"}
      readOnly={true}
      inputClassName="h-12 w-full border-[1px] border-accent rounded-md font-semibold bg-white text-primary cursor-pointer pl-2 text-sm focus:outline-none"
      containerClassName="relative"
      toggleClassName="absolute right-0 h-full px-3 text-primary focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed rounded"
      placeholder="Tarihler"
      popoverDirection="down"
      i18n={"tr"}
    />
  );
};

export default CheckIn;
